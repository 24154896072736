@import "tailwind/base.css";
@import "react-toastify/dist/ReactToastify.min.css";

* {
  font-family: 'Public Sans', sans-serif;
  font-weight: 400;
  line-height: 140% !important; /* 44.8px */
}

p, span, input, input::placeholder, textarea, textarea::placeholder, button {
  color: #DBD2BE;
}

button, input, textarea {
  user-select: none !important;
}

#root {
  background-color: black;
  /* height: calc(var(--vh) * 100); */
  min-height: 100dvh;
}

html, body {
  background-color: black;
}

.goldBoxShadow:hover {
  box-shadow: 0px 0px 18px 0px #F5E7C880;
}

