.base {
  background-image:
    linear-gradient(to bottom, black, black, transparent),
    url("app/assets/images/forestBg.webp");
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-position: bottom;
  position: relative;
  background-color: black;
  z-index: 1;
}

.content {
  overflow: auto;
  z-index: 10;
  position: relative;
}