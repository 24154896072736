.base {
  background-image: url("app/assets/images/forestBg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
  background-color: black;
}

.content, .bottomFade {
  margin: auto;
}

.content {
  overflow: auto;
  z-index: 10;
  position: relative;
}

.base::before {
  left: 0;
}

.base::after {
  right: 0;
  transform: rotateY(180deg); /* Flip the gradient for the right side */
}

.bottomFade {
  background: linear-gradient(180deg, #000 39.13%, rgba(0, 0, 0, 0.00) 100%);
  z-index: 10;
}

@media (min-width: 1025px) {
  .base::before, .base::after {
    content: "";
    position: absolute;
    top: 0;
    width: 30%; /* Adjust this value to control the width of the fade effect */
    height: 100%;
    z-index: 20;
    background: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0));
  }
}