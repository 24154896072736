.card {
  /* --hsl: var(--hue), var(--saturation), var(--lightness);
  --flow-space: 0.5em; */
  border-radius: 2px;
}

.cardFront {
  /* border: 1px solid transparent */
  border: 1px solid rgba(194,	185,	164, 0.8); /* Base color for the glow */
}

.cardBack {
  border: 1px solid #C2B9A4
}

.texture_bg {
  z-index: -10;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.flow > * + * {
  margin-top: var(--flow-space, 1.25em);
}

.cta {
  display: block;
  align-self: end;
  text-align: center;
  text-decoration: none;
  color: #fff;
  border-radius: 2px;
  font-size: 1rem;
  font-weight: 600;
}

.overlay {
  position: absolute;
  inset: 0;
  pointer-events: none;
  user-select: none;
  opacity: var(--opacity, 0);
  -webkit-mask: radial-gradient(
    25rem 25rem at var(--x) var(--y),
    #000 1%,
    transparent 50%
  );
  mask: radial-gradient(
    25rem 25rem at var(--x) var(--y),
    #000 1%,
    transparent 50%
  );
  transition: 400ms mask ease;
  will-change: mask;
  border-radius: 2px;
}

.overlay .cta {
  display: block;
  width: 100%;
  background-color: hsl(var(--hsl));
  box-shadow: 0 0 0 1px hsl(var(--hsl));
}

.overlay .card {
  border-color: hsla(var(--hsl), 1);
  box-shadow: 0 0 0 1px inset hsl(var(--hsl));
}

:not(.overlay) > .card {
  transition: 400ms background ease;
  will-change: background;
}

:not(.overlay) > .card:hover {
  --lightness: 95%; 
  opacity: 1
}

.cardBackGlow {
  animation: pulsingGlow 1.5s infinite ease-in-out;
}

@keyframes pulsingGlow {
  0% {
    box-shadow: 0 0 5px rgba(194,	185,	164, 0.4), 0 0 15px rgba(194,	185,	164, 0.4),
      0 0 20px rgba(194,	185,	164, 0.6);
    border-color: rgba(194,	185,	164, 0.8);
  }
  50% {
    box-shadow: 0 0 10px rgba(194,	185,	164, 0.6), 0 0 20px rgba(194,	185,	164, 0.8),
      0 0 30px rgba(194,	185,	164, 1);
    border-color: rgba(194,	185,	164, 1);
  }
  100% {
    box-shadow: 0 0 5px rgba(194,	185,	164, 0.4), 0 0 15px rgba(194,	185,	164, 0.4),
      0 0 20px rgba(194,	185,	164, 0.6);
    border-color: rgba(194,	185,	164, 0.8);
  }
}