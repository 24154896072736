.textAreaBase {
  background-image: linear-gradient(to right, #EC7F5A00 0%, #EC7F5A 100%, #EC7F5A00 0%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.inputWrapper {
  position: relative;
  width: 100%;
  border-radius: 0px;
  border: 1px solid transparent;
  padding: 10px 8px;
  background: linear-gradient(0deg, #101113, #101113),
linear-gradient(135.82deg, #0B0B0B -1.04%, #1F1F1E 100.43%);
  transition: all 0.3s ease;
  animation-duration: 0.3s;
}

.inputWrapper:hover, .focusInputWrapper {
  border: 1px solid #29200B;
  background: linear-gradient(135.82deg, #0B0B0B -1.04%, #1F1F1E 100.43%),
  linear-gradient(0deg, #29200B, #29200B);
}