.animatedBtnGradient {
  background: linear-gradient(180deg, #0B0B0B, #251C09);
  background-size: 200% 200%;
  animation: move-gradient 8s ease infinite;
}

.animatedBtnGradient::after {
  content: '';
  background: rgba(128, 128, 128, 0.2); /* Grey color with 50% opacity */
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; /* Make the overlay invisible initially */
  transition: opacity 0.3s; /* Smooth transition for the overlay */
  pointer-events: none; /* Let click events pass through to the button */
}

.animatedBtnGradient:hover::after {
  opacity: 1; /* Make the overlay visible on hover */
}

/* Apply the animation with media queries */

.animatedBgGlow, .animatedFrontalGlow {
  position: absolute;
  transition: all 0.3s ease-in-out;
  animation: fireFlicker 4s ease-in infinite;
  background-image: url("app/assets/images/chatGlow.svg");
  background-position: top;
  pointer-events: none;
  transform: translateY(60px);
  left: 0;
  bottom: 0;
  width: 100%;
}

.animatedFrontalGlow {
  height: 330px;
  z-index: 1;
}

.animatedFrontalGlowFocus {
  transform: translateY(30px);
}

.animatedBgGlow {
  height: 340px;
  z-index: -10;
  transform: scale(4);
  animation-delay: 2s;
}

.chatContainerFocus {
  position: relative; /* Make sure the parent element is relatively positioned */
}

.chatContainerFocus::before {
  content: '';
  position: absolute;
  animation-duration: 0.3s;
  transition: all 0.3s ease;
  top: 5px;
  left: 0;
  right: 0;
  height: 2px; /* Adjust the height of the border */
  background-image: linear-gradient(to right, #EC7F5A00 0%, #DBD2BE 50%, #EC7F5A00 100%);
}

.chatTopFade {
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 90%);
  height: 40px;
  position: absolute;
  width: 100%;
  z-index: 10;
}

@keyframes fireFlicker {
  0%, 10% { opacity: 0.8; }
  15%,20% { opacity: 0.73; }
  22%,23% { opacity: 0.86; }
  25%,35% { opacity: 0.78; }
  39%,42% { opacity: 0.92; }
  44%,47% { opacity: 1; }
  49%,50% { opacity: 0.97; }
  52%,54% { opacity: 0.78; }
  57%,58% { opacity: 0.84; }
  60%,63% { opacity: 0.77; }
  65%,72% { opacity: 0.96; }
  77%,85% { opacity: 0.86; }
  90%,95% { opacity: 0.7; }
  100% { opacity: 0.8; }
}

@keyframes move-gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

/* Keyframes for scaling on mobile (1.5x) */
@keyframes opacity-animation {
  0%, 100% {
   opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
}