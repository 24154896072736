.sheenFadeShadow {
  position: relative;
  animation: fadeShadow 5s infinite;
  border-radius: 10px;
}

.sheenFadeShadow::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, rgba(168, 159, 139, 0) 0%, rgba(240, 229, 206, 0.6) 50%, rgba(168, 159, 139, 0) 100%) no-repeat;
  background-size: 200% 100%;
  animation: sheen 8s infinite linear; /* Total cycle duration */
  z-index: 1;
  border-radius: 10px;
}

.button {
  border: 1px solid transparent;
  border-radius: 1px;
  transition-duration: all 0.5s ease-in-out;
}

/* primary */

.primary {
  color: black;
  padding: 10px;
  border: 1px solid #A89A7C;
  font-family: "Aquino";
  background: radial-gradient(103.57% 103.57% at 50% -28.57%, #FFF1D2 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(195.95% 138.16% at 50% 131.58%, #C2B495 35.24%, rgba(255, 255, 255, 0.00) 100%), #F5E7C8;
}

.primary:hover {
  color: #29200B;
  border: 1px solid var(--hm-ecru-hme-4, #A89A7C);
  background: radial-gradient(103.57% 103.57% at 50% -28.57%, #FFF1D2 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(107.37% 185.42% at 50% 154.17%, #5C4E2F 9.55%, #C2B495 77.53%, rgba(255, 255, 255, 0.00) 100%), #F5E7C8;
}

.primary:active {
  border: 1px solid var(--Titanium-White, #FFF);
  background: #F5E7C8;
  box-shadow: 0px 0px 8px 0px #291B00 inset;
}

/* secondary */

.secondary1, .secondary2, .secondary3 {
  min-width: 170px;
  padding: 9px;
  color: var(--pale-ecru-pe-0, #DBD2BE);
}

.secondary1:active, .secondary2:active {
  color: var(--dark-dark-3, #5C5F66);
}

.secondary1{
  background-image: var(--linear-linear-2, linear-gradient(136deg, #0B0B0B -1.04%, #1F1F1E 100.43%)),
  linear-gradient(135deg, #0B0B0B 15.04%, #dbd2be65 100.43%);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: 1px solid transparent;
  border-radius: 2px;
}

.secondary1:hover {
  background: linear-gradient(174deg, #0B0B0B 34.5%, rgba(117, 108, 88, 0.92) 130.93%);
}

.secondary2{
  background: linear-gradient(136deg, #000 -1.04%, #161616 100.43%);
}

.secondary2:hover {
  border: 1px solid var(--pale-ecru-pe-2, #A89F8B);
}

.secondary3 {
  border: 1px solid rgba(245, 231, 200, 0.06);
  background: radial-gradient(103.57% 103.57% at 50% -28.57%, rgba(245, 231, 200, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(50% 60.71% at 50% 110.71%, rgba(245, 231, 200, 0.60) 0%, rgba(0, 0, 0, 0.00) 100%), #000;
}

.secondary3:hover {
  background: radial-gradient(103.57% 103.57% at 50% -28.57%, rgba(245, 231, 200, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(91.18% 110.71% at 50% 110.71%, rgba(245, 231, 200, 0.60) 0%, rgba(0, 0, 0, 0.00) 100%), #000;
}

.secondary3:active {
  background: linear-gradient(136deg, #0B0B0B 72.07%, #1F1F1E 100.43%);
}

/* tertiary */

.tertiary {
  padding: 10px;
  color: var(--pale-ecru-pe-0, #DBD2BE);
  background: transparent;
}

.tertiary:hover {
  color: var(--pale-ecru-pe-2, #A89F8B);
}

.auth {
  /* flex p-2 px-4 m-auto !h-[40px] rounded-[2px] w-full */
  display: flex;
  border-radius: 2px;
  width: 100%;
  height: 80px !important;
  padding: 2px 4px;
  gap: 10px !important;
  background: var(--HM-Dark-HM-Dark-9, #0B0B0B);
  border: 1px solid var(--HM-Ecru-HM-E-6, #5C4E2F);
  font-size: 20px;
}

.auth:hover {
  background: var(--HM-Dark-shade-HMDS-9, #1A1814);
}

.auth:active {
  background: var(--Void-Black, #000);
}

@media screen and (max-width: 768px) {
  .auth {
    font-size: 14px;
    height: 60px !important;
  }
  
}

.icon {
  border-radius: 50px;
  border: 1px solid #0B0B0B !important;
  background: radial-gradient(189.09% 189.09% at 17.19% 17.19%, #0B0B0B 15.96%, #251C09 86.95%);
  position: relative;
  padding: 8px !important;
  max-height: 32px !important;
  max-width: 32px !important;
}

.icon:hover {
  border: 1px solid var(--Pale-Ecru-PE-2, #A89F8B) !important;
  background: linear-gradient(136deg, #000 -1.04%, #161616 100.43%);
}

.iconActive {
  background: var(--Pale-Ecru-PE-0, #DBD2BE) !important;
}


@keyframes fadeShadow {
  0% {
    box-shadow: 0 0 10px 0 rgba(168, 159, 139, 0);
  }
  50% {
    box-shadow: 0 0 10px 5px rgba(168, 159, 139, 0.2);
  }
  100% {
    box-shadow: 0 0 10px 0 rgba(168, 159, 139, 0);
  }
}

@keyframes sheen {
  0% {
    background-position: 200% 0; /* Start from the left, off-screen */
  }
  20% {
    background-position: -200% 0; /* End at the right, off-screen, by 1 second */
  }
  100% {
    background-position: -200% 0; /* Stay at the final position until 5 seconds are complete */
  }
}