.selectedCard {
  padding-bottom: 0;
  animation: zoom-and-shrink 0.5s ease forwards;
}

.animatedBgGlow, .animatedFrontalGlow {
  position: absolute;
  transition: all 0.3s ease-in-out;
  animation: fireFlicker 4s ease-in infinite;
  background-image: url("app/assets/images/chatGlow.svg");
  background-position: top;
  pointer-events: none;
  transform: translateY(60px);
  left: 0;
  bottom: 0;
  width: 100%;
}

.animatedFrontalGlow {
  height: 340px;
  z-index: 1;
  transform: scale(1.5);
}

.animatedFrontalGlowFocus {
  transform: translateY(30px);
}

.animatedBgGlow {
  height: 400px;
  z-index: -10;
  transform: scale(2);
  animation-delay: 2s;

}

.goldNoiseBg {
  border: 1px solid;
  width: 100%;
  position: absolute;
}

.movingBg {
  position: absolute;
  z-index: -100;
  width: 100%;
  height: 100%;
  --primary:  black;
  --secondary: #000000;
}

.movingBg::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse, var(--primary) 0%, var(--secondary) 80%);
  background-size: 75vw 75vw;
  background-repeat: no-reapeat;
  animation: translateCricle 13s linear infinite;
}

.animatedBtnGradient {
  min-width: 0 !important;
  height: auto;
  padding: 4px;
  width: 32px !important;
  height: 32px !important;
  background: linear-gradient(180deg, #0B0B0B, #251C09);
  background-size: 200% 200%;
  animation: move-gradient 8s ease infinite;
}

.animatedBtnGradient::after {
  content: '';
  background: rgba(128, 128, 128, 0.2); /* Grey color with 50% opacity */
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; /* Make the overlay invisible initially */
  transition: opacity 0.3s; /* Smooth transition for the overlay */
  pointer-events: none; /* Let click events pass through to the button */
}

.animatedBtnGradient:hover::after {
  opacity: 1; /* Make the overlay visible on hover */
}

.hmcTitleBlock {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 80px;
  height: 80px;
}

.topTarotFade, .bottomTarotFade {
  pointer-events: none;
}

.topTarotFade{
  background: linear-gradient(180deg, #000 50%, rgba(0, 0, 0, 0.00) 100%);
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 40px;
}

.bottomTarotFade{
  background: linear-gradient(360deg, #000 50%, rgba(0, 0, 0, 0.00) 100%);
  position: sticky;
  width: 100%;
  height: 300px;
}



@media screen and (max-width: 768px) {
  .hmcTitleBlock {
    top: 10px;
    right: 7px;
    /* transform: scale(0.5); */
    width: 40px;
    height: 40px;
  }
  /* .animatedBtnGradient {
    top: 10px;
    left: 10px;
  } */
}

@keyframes move-gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@keyframes translateCricle {
  0% {
    background-position: top 0 right 0;
    opacity: 0.75;
  }
  33% {
    background-position: top 100% right 0;
    opacity: 1;
  }
  50% {
    background-position: top 100% right 100%;
    opacity: 0.9;
  }
  66% {
    background-position: top 0 right 100%;
    opacity: 1;
  }
  100% {
    background-position: top 0 right 0;
    opacity: 0.75;
  }
}

@keyframes zoom-and-shrink {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fireFlicker {
  0%, 10% { opacity: 0.8; }
  15%,20% { opacity: 0.73; }
  22%,23% { opacity: 0.86; }
  25%,35% { opacity: 0.78; }
  39%,42% { opacity: 0.92; }
  44%,47% { opacity: 1; }
  49%,50% { opacity: 0.97; }
  52%,54% { opacity: 0.78; }
  57%,58% { opacity: 0.84; }
  60%,63% { opacity: 0.77; }
  65%,72% { opacity: 0.96; }
  77%,85% { opacity: 0.86; }
  90%,95% { opacity: 0.7; }
  100% { opacity: 0.8; }
}