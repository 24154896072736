.typingBubble {
  display: flex;
  justify-content: space-between;
  background-color: #1A1814;
  padding: 8px 12px;
  border-radius: 2px;
  max-width: 60px;
  align-self: flex-start;
  width: 100%;
}


.dot, .moon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

 .eclipse {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

/* Animation for dots */
.eclipse {
  background: #312F2C;
  position: absolute;
  z-index: 10;
  transform: scale(1.05);
  animation: fio 2400ms infinite forwards;
}

.dot:nth-child(1) .eclipse {
  animation-delay: 400ms;
}

.dot:nth-child(2) .eclipse {
  animation-delay: 800ms;
}

.dot:nth-child(3) .eclipse {
  animation-delay: 1200ms;
}

@keyframes fio {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}