.container {
  width: 100%;
  max-width: 836px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 100px 8px;
}

.p, .li {
  color: var(--HM-Dark-shade-HMDS-0, #e8e8e7ae);
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 100;
  line-height: 120%; /* 16.8px */
}

.ul {
 list-style: inside;
}

.a {
  font-weight: 100;
  text-decoration: underline;
}

.h2 {
  color: var(--HM-Dark-shade-HMDS-0, #E8E8E7);
font-family: Public Sans;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 120%;
}

.h1 {
  color: var(--HM-Dark-shade-HMDS-0, #E8E8E7);
  font-family: Aquino;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
}