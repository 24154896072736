@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

 @layer components {
  /* .rounded-small {
    @apply !rounded-sm;
  }

  .rounded-medium {
    @apply !rounded;
  }

  .rounded-large {
    @apply !rounded-md;
  } */

  .border-primary {
    @apply !border-gray-800;
  }

  .border-medium {
    @apply !border-1;
  }
  
  .dropdown-primary {
    @apply !bg-gray-800;
  }

  button {
    @apply !h-auto !select-all;
  }

  .svg-icon-active {
    filter: invert(54%) sepia(92%) saturate(6326%) hue-rotate(216deg) brightness(93%) contrast(97%);
  }

  .svg-icon-white {
    filter: brightness(0) invert(1);
  }

  .text-xs {
    @apply !text-[12px];
  }

  .font-aquino {
    font-family: "Aquino";
  }

  .snippet {
    .pre {
      font-family: "Inter", sans-serif;
    }
  }
} 